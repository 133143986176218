import { styled } from '@mui/system'
import Divider from '@mui/material/Divider';
import Image from 'react-bootstrap/Image';
import '../Styles/nextdivider.css';

const GradientDivider = styled(Divider)({
    
    "&::before": {
    border: 'none',
    height: '5px',
    backgroundImage: 'linear-gradient(to right, #9EE4FF, #B1E9FF, #91BEE3, #818ABA)'},
    
    "&::after": {
        border: 'none',
        height: '5px',
        backgroundImage: 'linear-gradient(to left, #9EE4FF, #B1E9FF, #91BEE3, #818ABA)'},
    
    position: 'fixed',
    bottom: '1%',
    maxWidth: '95%',
    minWidth: '95%'

});

export default function NextDivider() {
    return(
            <GradientDivider variant="bottom">
                <a href="/site">
                    <Image src={"https://storage.googleapis.com/z_static/Scroll%20Down.png"} width={"50%"} height={"100%"}></Image>
                </a>
            </GradientDivider>
    )
};

/* THIS DID WORK AS FAR AS ADDING A LINEAR GRADIENT AS THE BACKGROUND. HOWEVER, THE STYLING OVERRODE AND MESSED UP ALL OTHER STYLES. 
RECALL, LINEAR GRADIENT FUNCTION RETURNS AN IMAGE. HAPPENS WHEN USING DOUBLE CURLY BRACES WHICH DENOTES JSX. WHEN THIS HAPPENS, I BELIEVE,
IT IS NOT APPLY THE STYLE CONSTANT DECLARED AT TOP.

sx={{style, background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'}}
*/