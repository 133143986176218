import React, { useState, useRef } from 'react';
import Socials from './Socials.js';
import '../Styles/contact.css'
import Container from '@mui/material/Container';
import writeData from '../Utils/AddData.js';
//import Context from '../Utils/Context.js';
import Alerter from '../Utils/AlertPrompt.js';


const Contact = props => {
  let ref=useRef(false);
  const [firstname, setName] = useState('');
  const [useremail, setEmail] = useState('');
  const [usermessage, setMessage] = useState('');
  //const [feedback, setFeedback] = useState(false);
  
  const resetForm = async () => {
    setEmail('');
    setName('');
    setMessage('');
    //setFeedback(false);
  }

  const handleSubmit = async (event) => {
    
    event.preventDefault();

    const result = await writeData(firstname, useremail, usermessage);

    if (result.success) {
        //setFeedback(true);
        ref.current = true;
        try {
          resetForm();
          console.log('Whatever')
        } catch (e) {
          console.log(e)
        }
    } else {
      //setFeedback(false);
    }
  }

  return (
      <Container className="contact">
        {ref.current && <Alerter bool={ref.current}/>}
        <div className="container">
          <div className="heading-wrapper">
            <div className="heading">
              <p className="title">
                Get in <br />
                touch.
              </p>
              <p className="separator" />
              <p className="subtitle">
                Contact the team directly using the below form.{''}
              </p>
            </div>
            <Socials />
          </div>
          <form id="contact-form" action="#" onSubmit={handleSubmit}>
            <input placeholder="Name" name="UserName" type="text" value={firstname} onChange={(e) => setName(e.target.value)} required />
            <input placeholder="Email" name="email" type="email" value={useremail} onChange={(e) => setEmail(e.target.value)} required />
            <textarea placeholder="Message" type="text" name="message" value={usermessage} onChange={(e) => setMessage(e.target.value)}/>
            <input className="button" id="submit" value="Submit" type="submit"/>
          </form>
        </div>
      </Container>
    );
  };

  export default Contact;