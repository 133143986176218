import * as React from 'react';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { grey, red } from '@mui/material/colors';

export default function Socials() {
  return (
    <Stack direction="row" spacing={3}>
      <XIcon sx={{color: grey[50]}}/>
      <FacebookIcon color="primary" />
      <GoogleIcon sx={{color: red[500]}} />
      <LinkedInIcon color="secondary" />
    </Stack>
  );
}