import LandingText from './LandingText.js';
import NextDivider from './NextDivider.js';
import StickyBar from './StickyBar.js';
import mobile from '../Media/Mobile.png';
import MobileDrawerMenu from './MobileDrawerMenu.js';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import dark from '../Media/DarkMascot.jpg';

/*The following component, Wrapper, enables a responsive background.*/
const Wrapper = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${mobile})`,
      backgroundSize: 'cover',
      position: 'fixed',
      minWidth: 'auto',
      minHeight: '100vh',
      maxWidth: '100vw'
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${dark})`,
      backgroundSize: 'cover',
      position: 'fixed',
      maxWidth: '100vw',
      minHeight: '100vh',
      minWidth: 'auto'
  
    }
  }));

function LandingPage() {
    return (
      <Wrapper>
        <MobileDrawerMenu />
        <StickyBar />
        <LandingText />
        <NextDivider />
      </Wrapper>
    );
  }
  
  export default LandingPage;