import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/*import { grey } from '@mui/material/colors';*/

const theme = createTheme();

theme.typography.h1 = {
    fontFamily: 'editundo',
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
    color: 'white',
    padding: '3.5em',
  };

export default function LandingText() {
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%', maxWidth: 500}}>
            <Typography variant='h1' gutterBottom>
                The Ground Truth. Since 2018.
            </Typography>
        </Box>
        </ThemeProvider>
    )
}