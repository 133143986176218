/*import Toast from 'react-bootstrap/Toast';

const Alerted = () => {
    return(
        <Toast className="d-inline-block m-1" variant="success">
            <Toast.Header>
            <strong>SUBMISSION PROMPT DIALOG</strong>
            <small>Timestamp - No. of Minutes Ago</small>
            </Toast.Header>
            <Toast.Body variant="light">
                Submitted successfully.
            </Toast.Body>
        </Toast>
    )
}

export default Alerted;*/

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Alerter = (bool) => {
        if (bool) {
            toast.success("Successfully submitted.", {autoClose: 5000});
        } else {
            toast.error("Failed to submit.", {autoClose: 5000});
        }
    return (
        <div>
            <ToastContainer autoClose={5000} />
        </div>
    )
}

export default Alerter;