import NavbarBrand from 'react-bootstrap/NavbarBrand';
import Image from 'react-bootstrap/Image';
import logo from '../Media/Logo.png';
import '../Styles/footer.css';

export default function Footer() {
    return(
        <NavbarBrand >
            <Image className="footer" src={logo} width={"10%"} height={"20%"}/>
        </NavbarBrand>
    )
};