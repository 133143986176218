import React from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import "../Styles/projects.css";

const Project = props => {
    const tech = {
      sass: 'fab fa-sass',
      css: 'fab fa-css3-alt',
      js: 'fab fa-js-square',
      react: 'fab fa-react',
      vue: 'fab fa-vuejs',
      d3: 'far fa-chart-bar',
      node: 'fab fa-node',
      google: <GoogleIcon />
    };
  
    const link = props.link || 'http://';
    const repo = props.repo || 'http://';
  
    return (
      <div className="project">
        <a className="project-link" href={link} target="_blank" rel="noopener noreferrer">
          <img className="project-image" src={props.img} alt={'Screenshot of ' + props.title} />
        </a>
        <div className="project-details">
          <div className="project-tile">
            <p className="icons">
              {props.tech.split(' ').map(t => (
                <i className={tech[t]} key={t} />
              ))}
            </p>
            {props.title}{' '}
          </div>
          {props.children}
          <div className="buttons">
            <a href={repo} target="_blank" rel="noopener noreferrer">
              View source <i className="fas fa-external-link-alt" />
            </a>
            <a href={link} target="_blank" rel="noopener noreferrer">
              Try it Live <i className="fas fa-external-link-alt" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const Projects = props => {
    return (
      <section id="projects">
        <div className="projects-container">
          <div className="heading">
            <h3 className="title">Zidualz Projects</h3>
            <p className="separator" />
            <p className="subtitle">
              Zidualz experience is vast with <u>most</u> involving complex situations, multiple asset classes, and sophisitcated participants.{' '}
              <a href="https://www.hrea.com/" target="_blank" rel="noopener noreferrer">
                HREA
              </a>
              {' '} acquisition by Zidualz in 2024.
            </p>
          </div>
          <div className="projects-wrapper">
            <Project
              title="PYRULE"
              img={'https://storage.googleapis.com/z_static/Pyrule.png'}
              tech="google"
              link="https://www.pyrule.com/"
              repo="https://github.com/"
            >
              <small>
                Built using Node.js, Google Cloud, Express, MongoDB, React, and Python.
              </small>
              <p>
                Full-stack, proprietary tool integrated with Zidualz data collection pipeline. Enables high-volume annotation of the vast text corpus extracted from real estate documents.
              </p>
            </Project>
          </div>
        </div>
      </section>
    );
  };


/*function Projects () {
    return(
        <div></div>
    )
};*/

export default Projects;