import React from 'react';
import LandingPage from './Components/LandingPage';
import NonLandingContainer from './Components/NonLanding';
import About from './Components/AboutPage';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {path:'/', element: <LandingPage />},
  {path: '/about', element: <About />},
 {path: '/site', element: <NonLandingContainer />},
 {path: '/projects', element: <Projects />},
 {path: '/contact', element: <Contact />}
])

function App() {
  return <RouterProvider router={router}/>
}

export default App;
