//Container for components, pages, and sections that follow the landing page.
//Enables scroll through webpage after clicking down arrow on landing page.
//Will have generic route.

import React from 'react';
import About from './AboutPage.js';
//import BasicCard from './Defintion.js';
import Projects from './Projects.js';
import Contact from './Contact.js';
import Footer from './Footer.js';

const NonLandingContainer = () => {
    return(
        <div class="mb-2">
        <About />
        <Projects />
        <Contact />
        <Footer />
        </div>
    )
};

export default NonLandingContainer;