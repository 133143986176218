import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
/*import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'*/
import './scss/custom.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
